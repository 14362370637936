import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

function AdditionalForm(props) {

  function onFormChange(e) {
    props.onFormChange(e.target.name, e.target.value);
  }

  const handleMuiSelectSwietlicaOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    props.onFormChange('swietlica', event.target.value);
  };

  const handleMuiSelectObiadyOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    props.onFormChange('obiady', event.target.value);
  };

  const handleMuiSelectJezykOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    props.onFormChange('jezyk', event.target.value);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Informacje dodatkowe
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="stan_zdrowia"
            name="stan_zdrowia"
            label="Ważne informacje o stanie zdrowia dziecka (choroby przewlekłe, przyjmowane leki, alergie, itp.)."
            fullWidth
            autoComplete="stan_zdrowia"
            value={props.formData.stan_zdrowia}
            onChange={onFormChange}
            error={props.errors.includes('stan_zdrowia')}
            helperText={ props.errors.includes('stan_zdrowia') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 180,
            }}
          />
        </Grid>
        {props.formData.klasa <= 3 &&
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="swietlica-label">Czy są Państwo zainteresowani korzystaniem dziecka ze świetlicy?</InputLabel>
            <Select
                labelId="swietlica"
                id="swietlica"
                name="swietlica"
                label="Czy są Państwo zainteresowani korzystaniem dziecka ze świetlicy?"
                value={props.formData.swietlica}
                onChange={handleMuiSelectSwietlicaOnChange}
                error={props.errors.includes('swietlica')}
                helperText={ props.errors.includes('swietlica') ? 'Proszę uzupełnić to pole' : ''}
            >
              <MenuItem value={1}>Tak - jesteśmy zainteresowani korzystaniem ze świetlicy</MenuItem>
              <MenuItem value={0}>Nie - nie jesteśmy zainteresowani korzystamiem ze świetlicy</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        }
        {props.formData.klasa >= 4 &&
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="jezyk-label">Wybór drugiego języka obcego nowożytnego od kl. IV</InputLabel>
            <Select
                labelId="jezyk"
                id="jezyk"
                name="jezyk"
                label="Wybór drugiego języka obcego nowożytnego od kl. IV"
                value={props.formData.jezyk}
                onChange={handleMuiSelectJezykOnChange}
                error={props.errors.includes('jezyk')}
                helperText={ props.errors.includes('jezyk') ? 'Proszę uzupełnić to pole' : ''}
            >
              <MenuItem value="j. hiszpański">j. hiszpański</MenuItem>
              <MenuItem value="j. niemiecki">j. niemiecki</MenuItem>
              <MenuItem value="j. francuski">j. francuski</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        }

        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="obiady-label">Czy są Państwo zainteresowani obiadami? (są one płatne, dostarczane przez firmę cateringową)</InputLabel>
            <Select
                labelId="obiady"
                id="obiady"
                name="obiady"
                label="Czy są Państwo zainteresowani obiadami? (są one płatne, dostarczane przez firmę cateringową)"
                value={props.formData.obiady}
                onChange={handleMuiSelectObiadyOnChange}
                error={props.errors.includes('obiady')}
                helperText={ props.errors.includes('obiady') ? 'Proszę uzupełnić to pole' : ''}
            >
              <MenuItem value={1}>Tak - jesteśmy zainteresowani obiadami</MenuItem>
              <MenuItem value={0}>Nie - nie jesteśmy zainteresowani obiadami</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="imieniny"
              name="imieniny"
              label="Dzień imienin dziecka"
              fullWidth
              autoComplete="imieniny"
              value={props.formData.imieniny}
              onChange={onFormChange}
              error={props.errors.includes('imieniny')}
              helperText={ props.errors.includes('imieniny') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 120,
              }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="hobby"
              name="hobby"
              label="Zainteresowania (hobby)"
              fullWidth
              autoComplete="hobby"
              value={props.formData.hobby}
              onChange={onFormChange}
              error={props.errors.includes('hobby')}
              helperText={ props.errors.includes('hobby') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 180,
              }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="shad"
              name="skad"
              label="Skąd Państwo dowiedzieli się o szkole?"
              fullWidth
              autoComplete="skad"
              value={props.formData.skad}
              onChange={onFormChange}
              error={props.errors.includes('skad')}
              helperText={ props.errors.includes('skad') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 180,
              }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="dlaczego"
              name="dlaczego"
              label="Dlaczego Państwo wybierają dziecku tę szkołę i czego oczekują?"
              fullWidth
              autoComplete="dlaczego"
              value={props.formData.dlaczego}
              onChange={onFormChange}
              error={props.errors.includes('dlaczego')}
              helperText={ props.errors.includes('dlaczego') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 180,
              }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="wspolpraca"
              name="wspolpraca"
              label="Czy i w jakiej formie wyrażają Państwo gotowość do WSPÓŁPRACY ze szkołą?"
              fullWidth
              autoComplete="wspolpraca"
              value={props.formData.wspolpraca}
              onChange={onFormChange}
              error={props.errors.includes('wspolpraca')}
              helperText={ props.errors.includes('wspolpraca') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 180,
              }}
          />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default AdditionalForm;
