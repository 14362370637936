import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Review from './Review';
import StartForm from "./StartForm";
import CandidateForm from "./CandidateForm";
import ParentsForm from "./ParentsForm";
import AdditionalForm from "./AdditionslForm";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
});

const steps = ['Start', 'Dane o kandydacie', 'Dane o rodzicach', 'Informacje dodatkowe', 'Podsumowanie'];


class Checkout extends React.Component {
  state = {
    activeStep: 0,
    formData: {
      agree: false,
      klasa: '',
      nazwisko: '',
      imie: '',
      data_urodzenia: '',
      miejsce_urodzenia: '',
      pesel: '',
      adres_zameldowania: '',
      adres_zamieszkania: '',
      obwod_szkola: '',
      obwod_szkola_adres: '',
      ojciec_nazwisko: '',
      ojciec_imie: '',
      ojciec_adres_zamieszkania: '',
      ojciec_telefon: '',
      ojciec_email: '',
      ojciec_miejsce_pracy: '',
      samotny_ojciec: false,
      matka_nazwisko: '',
      matka_imie: '',
      matka_adres_zamieszkania: '',
      matka_telefon: '',
      matka_email: '',
      matka_miejsce_pracy: '',
      samotna_matka: false,
      stan_zdrowia: '',
      swietlica: '',
      jezyk: '',
      obiady: '',
      imieniny: '',
      hobby: '',
      skad: '',
      dlaczego: '',
      wspolpraca: '',
    },
    errors: [],
    dialogIsOpen: false,
    dialogTitle: '',
    dialogText: '',
    apiRequest: null,
  };

  validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
  };

  validate = (step) => {
    let errors = [];
    switch (step) {
      case 0: {
        if (this.state.formData.agree === false)
          errors.push('agree');
        break;
      }
      case 1: {
        ['klasa', 'nazwisko', 'imie', 'data_urodzenia', 'miejsce_urodzenia', 'pesel', 'adres_zameldowania', 'adres_zamieszkania', 'obwod_szkola', 'obwod_szkola_adres'].forEach(fieldName => {
          if (this.state.formData[fieldName] === '')
            errors.push(fieldName);
        })
        if (this.state.formData.data_urodzenia === null) {
            errors.push('data_urodzenia');
        }
        // if (this.state.formData.data_urodzenia && this.state.formData.data_urodzenia)
        if (!/^([0-9]{11})$/.test(this.state.formData.pesel)) {
          errors.push('pesel');
        }
        break;
      }
      case 2: {
        if (this.state.formData.samotna_matka === false) {
          [
            'ojciec_nazwisko',
            'ojciec_imie',
            'ojciec_adres_zamieszkania',
            'ojciec_telefon',
            'ojciec_email',
            'ojciec_miejsce_pracy',
          ].forEach(fieldName => {
            if (this.state.formData[fieldName] === '')
              errors.push(fieldName);
          })
          if (!this.validateEmail(this.state.formData.ojciec_email)) {
            errors.push('ojciec_email');
          }
        }
        if (this.state.formData.samotny_ojciec === false) {
          [
            'matka_nazwisko',
            'matka_imie',
            'matka_adres_zamieszkania',
            'matka_telefon',
            'matka_email',
            'matka_miejsce_pracy',
          ].forEach(fieldName => {
            if (this.state.formData[fieldName] === '')
              errors.push(fieldName);
          })
          if (!this.validateEmail(this.state.formData.matka_email)) {
            errors.push('matka_email');
          }
        }
        break;
      }
      case 3: {
        [
          'stan_zdrowia',
          'swietlica',
          'jezyk',
          'obiady',
          'imieniny',
          'hobby',
          'skad',
          'dlaczego',
          'wspolpraca',
        ].forEach(fieldName => {
          if (this.state.formData.klasa >= 4 && fieldName === 'swietlica')
            return;
          if (this.state.formData.klasa <= 3 && fieldName === 'jezyk')
            return;
          if (this.state.formData[fieldName] === '')
            errors.push(fieldName);
        })
        break;
      }
    }
    this.setState(state => ({
      ...state,
      errors: errors,
    }));
    return errors.length === 0;

  }

  getStepContent(step, formData, errors) {
    switch (step) {
      case 0:
        return <StartForm formData={formData} onFormChange={this.handleChange} errors={errors} />;
      case 1:
        return <CandidateForm formData={formData} onFormChange={this.handleChange} errors={errors}/>;
      case 2:
        return <ParentsForm formData={formData} onFormChange={this.handleChange} errors={errors} />;
      case 3:
        return <AdditionalForm formData={formData} onFormChange={this.handleChange} errors={errors} />;
      case 4:
        return <Review formData={formData} onFormChange={this.handleChange} errors={errors} />;
      default:
        throw new Error('Unknown step');
    }
  }

  handleChange = (name, value) => {
    this.setState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
      [name]: value
      }
    }), () => {console.log(this.state)})
  }

  handleNext = () => {
    if (this.validate(this.state.activeStep)) {
      this.setState(state => ({
        ...state,
        activeStep: state.activeStep + 1,
      }), () => {
        if (this.state.activeStep === steps.length) {
          this.sendForm();
        }
      });
    } else {
      let dialogTitle = 'Formularz zawiera błędy';
      let dialogText = 'Proszę poprawnie wypełnić wszystkie wymagane pola formularza.';
      switch (this.state.activeStep) {
        case 0:
          dialogTitle = 'Formularz zawiera błędy';
          dialogText = 'Aby przygotować wniosek w formie elektronicznej, prosimy wyrazić zgodę na przetwarzanie danych osobowych.';
          break;
      }
      this.setState(state => ({
        ...state,
        dialogIsOpen: true,
        dialogTitle: dialogTitle,
        dialogText: dialogText,
      }));
    }
  };

  sendForm = () => {
    let formData = this.state.formData;
    formData.data_urodzenia = formData.data_urodzenia.format('yyyy-MM-DD')
    // formData.samotny_ojciec =
    // formData.samotna_matka =
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };
    fetch('https://rekrutacja.salezy.pl/backend.php', requestOptions)
        .then(response => response.json())
        .then(data => this.setState(state => ({...state, apiRequest: data })));
  }

  handleBack = () => {
    this.setState(state => ({
      ...state,
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      formData: {},
      errors: [],
      dialogIsOpen: false,
      dialogTitle: '',
      dialogText: '',
    });
  };

  handleDialogClose = () => {
    this.setState(state => ({
      ...state,
      dialogIsOpen: false,
    }));
  };

  render() {
    const { classes } = this.props;
    const { activeStep, dialogIsOpen, dialogText, dialogTitle, apiRequest} = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="absolute" color="default" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Salezjańska Szkoła Podstawowa w Legionowie
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Wniosek o przyjęcie dziecka
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                      apiRequest === null ? (
                          <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                              Proszę czekać...
                            </Typography>
                            <Typography variant="subtitle1">
                               Trwa wysyłanie formularza.
                            </Typography>
                          </React.Fragment>
                      ) : (
                          (apiRequest === false || (apiRequest && apiRequest.status === 'error')) ? (
                              <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                  Wystąpił błąd.
                                </Typography>
                                <Typography variant="subtitle1">
                                  Przepraszamy, z powodu porblemów technicznych twój wniosek nie został wysłany..
                                  <br/>Prosimy spróbować ponownie w późniejszym czasie.
                                </Typography>
                              </React.Fragment>
                          ) : (
                              <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                  Dziękujemy za wypełnienie wniosku.
                                </Typography>
                                <Typography variant="subtitle1">
                                  Twój wniosek został wysłany na wskazane w nim adresy e-mial rodziców. Prosimy o jego wydrukowanie, podpisanie i dostarczenie do sekretariatu szkoły wraz z pozostałymi wymaganymi dokumentami.
                                  <br/>
                                  <br/>
                                  Numer Twojego wniosku jest następujący: <b>{apiRequest.recruitment_number}</b>
                                </Typography>
                              </React.Fragment>
                          )
                      )
              ) : (
                <React.Fragment>
                  {this.getStepContent(activeStep, this.state.formData, this.state.errors )}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={this.handleBack} className={classes.button}>
                        Wstecz
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Wyślij wniosek' : 'Dalej'}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
        <Dialog
            open={dialogIsOpen}
            aria-labelledby={dialogTitle}
            aria-describedby={dialogText}
        >
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkout);
