import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";

import Stack from '@mui/material/Stack';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pl";

function CandidateForm(props) {

  const handleMuiSelectOnChange = (
      event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.persist();
    props.onFormChange('klasa', event.target.value, 1);
  };

  const handleDateChange = (value) => {
    props.onFormChange('data_urodzenia', value);
  }

  function onFormChange(e) {
    props.onFormChange(e.target.name, e.target.value);
  }

  moment.locale("pl")
  return (

  <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dane o kandydacie
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="klasa-label">Wniosek do klasy</InputLabel>
            <Select
                required
                name="klasa"
                labelId="klasa"
                id="klasa"
                label="Wniosek do klasy"
                value={props.formData.klasa}
                onChange={handleMuiSelectOnChange}
                error={props.errors.includes('klasa')}
                helperText={props.errors.includes('klasa') ? 'Proszę uzupełnić to pole' : ''}
            >
              <MenuItem value={1}>I</MenuItem>
              <MenuItem value={2}>II</MenuItem>
              <MenuItem value={3}>III</MenuItem>
              <MenuItem value={4}>IV</MenuItem>
              <MenuItem value={5}>V</MenuItem>
              <MenuItem value={6}>VI</MenuItem>
              <MenuItem value={7}>VII</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="nazwisko"
            name="nazwisko"
            label="Nazwisko"
            fullWidth
            autoComplete="fnazwisko"
            value={props.formData.nazwisko}
            onChange={onFormChange}
            error={props.errors.includes('nazwisko')}
            helperText={ props.errors.includes('nazwisko') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 120,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              required
              id="imie"
              name="imie"
              label="Imiona"
              fullWidth
              autoComplete="fimie"
              value={props.formData.imie}
              onChange={onFormChange}
              error={props.errors.includes('imie')}
              helperText={ props.errors.includes('imie') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 120,
              }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>

          <LocalizationProvider locale="PL" dateAdapter={DateAdapter} >
            <Stack spacing={3}>
              <MobileDatePicker
                  cancelText="Anuluj"
                  okText="Wybierz"
                  id="data_urodzenia"
                  label="Data urodzenia"
                  inputFormat="yyyy-MM-DD"
                  value={props.formData.data_urodzenia}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params}
                                                      error={props.errors.includes('data_urodzenia')}
                                                      helperText={ props.errors.includes('data_urodzenia') ? 'Proszę uzupełnić to pole' : ''}
                  />}

              />
            </Stack>
          </LocalizationProvider>




          {/*<TextField*/}
          {/*  required*/}
          {/*  id="data_urodzenia"*/}
          {/*  name="data_urodzenia"*/}
          {/*  label="Data urodzenia"*/}
          {/*  fullWidth*/}
          {/*  autoComplete="fdate"*/}
          {/*  value={props.formData.data_urodzenia}*/}
          {/*  onChange={onFormChange}*/}
          {/*  error={props.errors.includes('data_urodzenia')}*/}
          {/*  helperText={ props.errors.includes('data_urodzenia') ? 'Proszę uzupełnić to pole' : ''}*/}
          {/*/>*/}
        </Grid>
        <Grid item xs={12} sm={6}>

        <TextField
            required
            id="miejsce_urodzenia"
            name="miejsce_urodzenia"
            label="Miejsce urodzenia oraz województwo"
            fullWidth
            autoComplete="fmiejsce_urodzenia"
          value={props.formData.miejsce_urodzenia}
          onChange={onFormChange}
            error={props.errors.includes('miejsce_urodzenia')}
            helperText={ props.errors.includes('miejsce_urodzenia') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 180,
            }}
        />
      </Grid>

        <Grid item xs={12}>
          <TextField
              required
              id="pesel"
              name="pesel"
              label="PESEL"
              fullWidth
              autoComplete="pesel"
              value={props.formData.pesel}
              onChange={onFormChange}
              error={props.errors.includes('pesel')}
              helperText={ props.errors.includes('pesel') ? 'Proszę uzupełnić to pole. Pole powinno zawierać 11 cyfr.' : ''}
              inputProps={{
                maxLength: 11,
              }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="adres_zameldowania"
            name="adres_zameldowania"
            label="Adres stałego zameldowania"
            fullWidth
            autoComplete="fadres zameldowania"
            value={props.formData.adres_zameldowania}
            onChange={onFormChange}
            error={props.errors.includes('adres_zameldowania')}
            helperText={ props.errors.includes('adres_zameldowania') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 180,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="adres_zamieszkania"
            name="adres_zamieszkania"
            label="Adres zamieszkania"
            fullWidth
            autoComplete="fadres zamieszkania"
            value={props.formData.adres_zamieszkania}
            onChange={onFormChange}
            error={props.errors.includes('adres_zamieszkania')}
            helperText={ props.errors.includes('adres_zamieszkania') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 180,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="obwod_szkola"
            name="obwod_szkola"
            label="Kandydat należy do obwodu Szkoły Podstawowej Nr"
            fullWidth
            autoComplete="fobwod_szkola"
            value={props.formData.obwod_szkola}
            onChange={onFormChange}
            error={props.errors.includes('obwod_szkola')}
            helperText={ props.errors.includes('obwod_szkola') ? 'Proszę uzupełnić to pole' : ''}
            inputProps={{
              maxLength: 180,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
              required
              id="obwod_szkola_adres"
              name="obwod_szkola_adres"
              label="Adres powyższej szkoły"
              fullWidth
              autoComplete="fobwod_szkola_adres"
              value={props.formData.obwod_szkola_adres}
              onChange={onFormChange}
              error={props.errors.includes('obwod_szkola_adres')}
              helperText={ props.errors.includes('obwod_szkola_adres') ? 'Proszę uzupełnić to pole' : ''}
              inputProps={{
                maxLength: 180,
              }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CandidateForm;
