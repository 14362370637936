import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function ParentsForm(props) {

  function onFormChange(e) {
      if (e.target.name === 'samotny_ojciec' || e.target.name === 'samotna_matka') {
          props.onFormChange(e.target.name, e.target.checked, 2);
      } else {
          props.onFormChange(e.target.name, e.target.value, 2);
      }
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dane o rodzicach lub opiekunach prawnych
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom style={{marginTop: '32px'}}>
            Ojciec <i>(lub prawny opiekun)</i>
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <TextField
                  required
                  disabled={props.formData.samotna_matka}
                  id="ojciec_nazwisko"
                  name="ojciec_nazwisko"
                  label="Nazwisko"
                  fullWidth
                  autoComplete="ojciec_nazwisko"
                  value={props.formData.ojciec_nazwisko}
                  onChange={onFormChange}
                  error={props.errors.includes('ojciec_nazwisko')}
                  helperText={ props.errors.includes('ojciec_nazwisko') ? 'Proszę uzupełnić to pole' : ''}
                  inputProps={{
                    maxLength: 120,
                  }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotna_matka}
                id="ojciec_imie"
                name="ojciec_imie"
                label="Imię"
                fullWidth
                autoComplete="ojciec_imie"
                value={props.formData.ojciec_imie}
                onChange={onFormChange}
                error={props.errors.includes('ojciec_imie')}
                helperText={ props.errors.includes('ojciec_imie') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 120,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotna_matka}
                id="ojciec_adres_zamieszkania"
                name="ojciec_adres_zamieszkania"
                label="Adres zamieszkania"
                fullWidth
                autoComplete="ojciec_adres_zamieszkania"
                value={props.formData.ojciec_adres_zamieszkania}
                onChange={onFormChange}
                error={props.errors.includes('ojciec_adres_zamieszkania')}
                helperText={ props.errors.includes('ojciec_adres_zamieszkania') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 180,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotna_matka}
                id="ojciec_telefon"
                name="ojciec_telefon"
                label="Telefon kontaktowy"
                fullWidth
                autoComplete="ojciec_telefon"
                value={props.formData.ojciec_telefon}
                onChange={onFormChange}
                error={props.errors.includes('ojciec_telefon')}
                helperText={ props.errors.includes('ojciec_telefon') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 32,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotna_matka}
                id="ojciec_email"
                name="ojciec_email"
                label="E-mail"
                fullWidth
                autoComplete="ojciec_email"
                value={props.formData.ojciec_email}
                onChange={onFormChange}
                error={props.errors.includes('ojciec_email')}
                helperText={ props.errors.includes('ojciec_email') ? 'Proszę uzupełnić to pole. Pole powinno zawierać prawidłowy adres e-mail.' : ''}
                inputProps={{
                  maxLength: 120,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotna_matka}
                id="ojciec_miejsce_pracy"
                name="ojciec_miejsce_pracy"
                label="Miejsce pracy"
                fullWidth
                autoComplete="ojciec_miejsce_pracy"
                value={props.formData.ojciec_miejsce_pracy}
                onChange={onFormChange}
                error={props.errors.includes('ojciec_miejsce_pracy')}
                helperText={ props.errors.includes('ojciec_miejsce_pracy') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 180,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{marginTop: '16px'}}>
              <FormControlLabel
                  control={
                      <Checkbox
                          disabled={props.formData.samotna_matka}
                          name="samotny_ojciec"
                          checked={props.formData.samotny_ojciec}
                          onChange={onFormChange}
                          error={props.errors.includes('samotny_ojciec')}
                          helperText={ props.errors.includes('samotny_ojciec') ? 'Proszę uzupełnić to pole' : ''}
                      />}
                  label="Jestem rodzicem lub opiekunem prawnym samotnie wychowującym dziecko."
              />
          </Grid>
        </Grid>

          <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom style={{marginTop: "32px"}}>
            Matka <i>(lub prawny opiekun)</i>
          </Typography>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <TextField
                  required
                  disabled={props.formData.samotny_ojciec}
                  id="matka_nazwisko"
                  name="matka_nazwisko"
                  label="Nazwisko"
                  fullWidth
                  autoComplete="matka_nazwisko"
                  value={props.formData.matka_nazwisko}
                  onChange={onFormChange}
                  error={props.errors.includes('matka_nazwisko')}
                  helperText={ props.errors.includes('matka_nazwisko') ? 'Proszę uzupełnić to pole' : ''}
                  inputProps={{
                    maxLength: 120,
                  }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotny_ojciec}
                id="matka_imie"
                name="matka_imie"
                label="Imię"
                fullWidth
                autoComplete="matka_imie"
                value={props.formData.matka_imie}
                onChange={onFormChange}
                error={props.errors.includes('matka_imie')}
                helperText={ props.errors.includes('matka_imie') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 120,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotny_ojciec}
                id="matka_adres_zamieszkania"
                name="matka_adres_zamieszkania"
                label="Adres zamieszkania"
                fullWidth
                autoComplete="matka_adres_zamieszkania"
                value={props.formData.matka_adres_zamieszkania}
                onChange={onFormChange}
                error={props.errors.includes('matka_adres_zamieszkania')}
                helperText={ props.errors.includes('matka_adres_zamieszkania') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 180,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotny_ojciec}
                id="matka_telefon"
                name="matka_telefon"
                label="Telefon kontaktowy"
                fullWidth
                autoComplete="matka_telefon"
                value={props.formData.matka_telefon}
                onChange={onFormChange}
                error={props.errors.includes('matka_telefon')}
                helperText={ props.errors.includes('matka_telefon') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 32,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotny_ojciec}
                id="matka_email"
                name="matka_email"
                label="E-mail"
                fullWidth
                autoComplete="matka_email"
                value={props.formData.matka_email}
                onChange={onFormChange}
                error={props.errors.includes('matka_email')}
                helperText={ props.errors.includes('matka_email') ? 'Proszę uzupełnić to pole. Pole powinno zawierać prawidłowy adres e-mail.' : ''}
                inputProps={{
                  maxLength: 120,
                }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
                required
                disabled={props.formData.samotny_ojciec}
                id="matka_miejsce_pracy"
                name="matka_miejsce_pracy"
                label="Miejsce pracy"
                fullWidth
                autoComplete="matka_miejsce_pracy"
                value={props.formData.matka_miejsce_pracy}
                onChange={onFormChange}
                error={props.errors.includes('matka_miejsce_pracy')}
                helperText={ props.errors.includes('matka_miejsce_pracy') ? 'Proszę uzupełnić to pole' : ''}
                inputProps={{
                  maxLength: 180,
                }}
            />
          </Grid>
            <Grid item xs={12} sm={12} style={{marginTop: '16px'}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="samotna_matka"
                            disabled={props.formData.samotny_ojciec}
                            checked={props.formData.samotna_matka}
                            onChange={onFormChange}
                            error={props.errors.includes('samotna_matka')}
                            helperText={ props.errors.includes('samotna_matka') ? 'Proszę uzupełnić to pole' : ''}
                        />}
                    label="Jestem rodzicem lub opiekunem prawnym samotnie wychowującym dziecko."
                />
            </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ParentsForm;
