import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function StartForm(props) {

    function onFormChange(e) {
        if (e.target.name === 'agree') {
            props.onFormChange(e.target.name, e.target.checked, 0);
        } else {
            props.onFormChange(e.target.name, e.target.value, 0);
        }
    }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Zapraszamy do wypełnienia wniosku
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={12}>
          <p>
            Zapraszamy do złożenia wniosku o przyjęcie dziecka do Salezjańskiej Szkoły Podstawowej w Legionowie. Można go złożyć w formie elektronicznej lub papierowej (patrz: załączniki na podstronach zawierających kalendarium rekrutacji).
          </p>
          <p>
            Elektroniczny wniosek składa się z pięciu etapów. Są to:
          </p>

            <ol>
                  <li>Start</li>
                  <li>Dane o kandydacie</li>
                  <li>Dane o rodzicach</li>
                  <li>Informacje dodatkowe</li>
                  <li>Podsumowanie</li>

                  {/*<li>Start - rozpoczęcie wniosku. To miejsce, w którym jesteś teraz. Etap ten zawiera instrukcję wypełnienia i złożenia wniosku.</li>*/}
                  {/*<li>Dane o kandydacie - w tej sekcji proszę wprowadzzić dane kandydata.</li>*/}
                  {/*<li>Dane o rodzicach - w tej sekcji proszę wprowadzzić dane rodziców.</li>*/}
                  {/*<li>Informacje dodatkowe - w tej sekcji prosimy udzielić informacji uzupełniających.</li>*/}
                  {/*<li>Podsumowanie - w tej sekcji sprawdzić i wysłać wniosek.</li>*/}
              </ol>
            {/*<Typography variant="h6" gutterBottom>*/}
            {/*    Kontaktowy adres e-mail*/}
            {/*</Typography>*/}
            <p>
                Po wypełnieniu formularza prześlemy na Państwa adres e-mail gotowy wniosek. <b>Prosimy o jego wydrukowanie, podpisanie i dostarczenie</b> do sekretariatu szkoły wraz z pozostałymi wymaganymi dokumentami.
            </p>
            {/*<p>Proszę podać adres e-mail, na który zostanie przesłany wniosek.</p>*/}
        </Grid>
          {/*<Grid item xs={12} sm={12}>*/}
          {/*    <TextField required id="email" label="Adres e-mail" fullWidth />*/}
          {/*</Grid>*/}
          <Grid item xs={12} sm={12}>
              <p></p>
              <Typography variant="h6" gutterBottom>
                  Dane osobowe
              </Typography>
              <p style={{textAlign: 'justify'}}>
                  Klauzula informacyjna: Zgodnie z art. 13 ust. 1-2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych (Dz.U. UE L 119, s. 1) – dalej RODO – informujemy, że Administratorem Państwa danych osobowych jest Salezjańska Szkoła Podstawowa. Dane przetwarzane są w celu realizacji czynności urzędowych, związanych z procesem rekrutacyjnym do szkoły. Szkoła przetwarza dane osobowe uczniów, ich rodziców lub opiekunów prawnych w ramach wykonywania ustawowych obowiązków na podstawie art 6 ust 1 lit c) lub art 9 ust 2 lit g) RODO, czyli wypełnienia przez szkołę obowiązku, w ściśle określonych celach oraz zakresie, na podstawie przepisów prawa. Mają Państwo prawo do dostępu i sprostowania danych oraz ograniczenia przetwarzania danych na zasadach określonych w art. 20 RODO. Mają Państwo prawo złożenia skargi na niezgodne z prawem przetwarzanie danych osobowych do Prezesa Urzędu Ochrony Danych Osobowych, ul Stawki 2, 00-193 Warszawa.
              </p>
          </Grid>
          <Grid item xs={12}>
              <FormControlLabel
                  control={
                      <Checkbox
                          name="agree"
                          checked={props.formData.agree}
                          onChange={onFormChange}
                          error={props.errors.includes('agree')}
                          helperText={ props.errors.includes('agree') ? 'Proszę uzupełnić to pole' : ''}
                      />}
                  label="Oświadczam, że zapoznałem się z klauzulą informacyjną i wyrażam zgodę na elektroniczne przetwarzanie danych osobowych."
              />
          </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default StartForm;
