import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

const fieldsGroups = [
  {
    name: "Dane o kandydacie",
    fields: [
      {name: 'nazwisko', label: 'Nazwisko'},
      {name: 'imie', label: 'Imię'},
      // {name: 'data_urodzenia', label: 'Data urodzenia'},
      {name: 'pesel', label: 'Numer PESEL'},
      {name: 'miejsce_urodzenia', label: 'Miejsce urodzenia oraz województwo'},
      {name: 'adres_zameldowania', label: 'Adres stałego zameldowania'},
      {name: 'adres_zamieszkania', label: 'Adres zamieszkania'},
    ]
  }
]


const styles = theme => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});

function Review(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Podsumowanie wniosku
      </Typography>
      <p>
        Przed wysłaniem prosimy sprawdzić dane wprowadzone do wniosku.
      </p>
      <Grid container spacing={24}>
        {fieldsGroups.map(group => (
            <Grid item xs={12} sm={12} style={{textAlign: 'center'}}>
              <Typography variant="h6" gutterBottom>
                {group.name}
              </Typography>
              <List disablePadding>
                {group.fields.map(field => (
                    <ListItem className="aaa" key={field.name}>
                      <ListItemText style={{textAlign: 'center'}} primary={field.label} secondary={props.formData[field.name]} />
                    </ListItem>
                ))}
              </List>
            </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review);
