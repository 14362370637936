import logo from './logo.svg';
import './App.css';
import Checkout from "./Checkout";

function App() {
  return (
      <Checkout></Checkout>
  );
}

export default App;
